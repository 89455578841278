var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto px-2 md:px-0" },
    [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _c(
        "div",
        {
          staticClass:
            "flex grid grid-cols-2 md:grid-cols-4 xl:gap-12 lg:gap-6 gap-3",
        },
        _vm._l(_vm.services, function (service, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "col-span-1 content-center flex flex-col rounded-lg border overflow-hidden",
            },
            [
              _c("p", { staticClass: "flex-0 font-semibold mx-3 my-2" }, [
                _vm._v(_vm._s(service.title)),
              ]),
              _c("img", {
                staticClass: "h-32 mx-auto mb-6",
                attrs: { src: service.image.src },
              }),
              _c("span", {
                staticClass:
                  "text-justify flex-1 mb-4 text-sm text-gray-600 mx-3",
                domProps: { innerHTML: _vm._s(service.description) },
              }),
              _c(
                "div",
                { staticClass: "h-11 flex-0 flex mb-2 mx-3" },
                [
                  service.linkurl
                    ? [
                        _c(
                          "a",
                          {
                            staticClass: "my-auto mr-0 ml-auto underline",
                            attrs: { href: service.linkurl, target: "_blank" },
                          },
                          [
                            _c("span", [
                              _vm._v("詳細はこちら "),
                              _c(
                                "svg",
                                {
                                  staticClass: "w-5 h-5 inline mb-1",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "none",
                                    viewBox: "0 0 24 24",
                                    "stroke-width": "1.5",
                                    stroke: "currentColor",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : [
                        _c("span", { staticClass: "my-auto mr-0 ml-auto" }, [
                          service.payafter
                            ? _c("img", {
                                staticClass:
                                  "border rounded-full border-black inline mr-4 h-11",
                                attrs: {
                                  src: require("@/assets/image/label-pl.png"),
                                },
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              _vm._s(service.price.toLocaleString()) + "円"
                            ),
                          ]),
                          _c("span", [_vm._v(_vm._s(service.priceextra))]),
                        ]),
                      ],
                ],
                2
              ),
              _c("div", { staticClass: "w-full" }, [
                _c(
                  "button",
                  {
                    staticClass: "w-full h-full py-2 text-center font-bold",
                    class: _vm.selected.includes(index)
                      ? "bg-very-light-grey"
                      : "bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                    on: {
                      click: function ($event) {
                        return _vm.toggleSelection(index)
                      },
                    },
                  },
                  [
                    _vm.selected.includes(index)
                      ? _c("span", [_vm._v("選択を解除する")])
                      : _c("span", [_vm._v("選択する")]),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _vm.loginUserRole === "provider"
        ? _c("div", { staticClass: "text-center mb-4" }, [
            _c(
              "button",
              {
                staticClass:
                  "w-full md:w-4/12 mx-auto h-full mt-8 py-3 rounded text-center font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                attrs: { disabled: !_vm.selected.length },
                on: {
                  click: function ($event) {
                    return _vm.sendSelection()
                  },
                },
              },
              [_vm._v(" 選択したサービスについて問い合わせる ")]
            ),
          ])
        : _vm._e(),
      _vm._m(4),
      _c("NoticeModal", {
        attrs: {
          modalName: "sentRequestModal",
          contents:
            "お申込みありがとうございます。数日以内に、担当者よりメールにてご連絡を差し上げますのでしばらくお待ちください。",
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full inline-block" }, [
      _c("h1", { staticClass: "text-2xl font-normal pt-2" }, [
        _vm._v("有償サービス申し込みページ"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-full inline-block text-right text-xs mb-4" },
      [_c("span", [_vm._v("※表示価格は、全て税別表記になります。")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-2/3 mx-auto text-justify mb-8 rounded bg-light-grey p-4",
      },
      [
        _c("p", { staticClass: "mb-4 text-center" }, [
          _vm._v("有償サービスとは？"),
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            "弊社のオンラインフィルムマーケットは、オンライン上のプラットフォームサービスとなります。"
          ),
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            " これは、可能なかぎり人を介さずに海外との取引を可能にすることで、大型の契約だけでなく、通常の代理店では取り扱えない小規模な契約についても成立させ、日本映画の海外でのチャンスを拡大し、またそれによって、ライセンサー様に少しでも多くの利益をお届けることを目的としております。 "
          ),
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            " 結果として多数の作品を世界に提供できており、お蔭様で一定の成功を収めていると考えております。 "
          ),
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            " これを継続したビジネスとして成立させるために、可能なかぎりコストを抑える必要があり、オンライフィルムマーケット上で対応できない作業＝自動化できない作業につきましては、別途有償での対応をさせていただいております。 "
          ),
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            " サービスのご利用を検討される場合には、必要なサービスの選択ボタン（同時に複数選択が可能）をクリックの上、画面下の「選択したサービスに問い合わせる」ボタンをクリックしてください。担当者より、オンライフィルムマーケットにご登録のメールアドレスにご連絡を差し上げます。 "
          ),
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            " 弊社のオンラインフィルムマーケットは、プラットフォームサービスとなりまして、可能なかぎり人を介さずに海外との売買を成立させることで、通常の代理店では取り扱えない小規模な契約を成立させ、またライセンサー様に少しでも多くの利益をお届けることを目的としております。 "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full text-justify mb-8" }, [
      _c("span", { staticClass: "text-sm" }, [
        _vm._v(
          "サービスを申し込まれるには、必要なサービスの選択ボタン（同時に複数選択が可能）をクリックの上、画面下の「選択したサービスを申し込む」ボタンをクリックしてください。担当者よりメールにてご連絡を差し上げます。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-2/3 mx-auto text-justify mb-8 rounded bg-light-grey p-4",
      },
      [
        _c("p", { staticClass: "font-semibold" }, [
          _vm._v("〇サービスお申し込みまでの流れ"),
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            "上記ボタンよりお問い合わせいただいた後、弊社担当者より、サービス詳細についての資料をメールでお送りいたします。"
          ),
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            "その内容をご理解、ご承諾いただいた上で、お申し込みをとなります。"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }